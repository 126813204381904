<template>
  <div class="progress">
      <div :class="['progress-width', 'progress-width' + index]" :style="{ width: width + '%'}"></div>
      <div :class="['progress-circle99', 'progress-circle' + index]" :style="{ left: width + '%'}"></div>
  </div>
</template>

<script>
import { number } from 'echarts'
export default {
   props: {
      width: {
         type: [Number,String],
         default: 30
      },
      index: {
         type: Number,
         default: 0
      }
   }
}
</script>

<style lang="scss" scoped>
   .progress {
      position: relative;
      height: 6px;
      background: rgba(255,255,255,0.1);
      border-radius: 3px;
      .progress-width {
         height: 6px;
      }
      .progress-width0 {
         height: 6px;
         background: linear-gradient(to right, rgba(2, 103, 203, .1),rgba(2, 103, 203, 1));
      }
      .progress-width1 {
         height: 6px;
         background: linear-gradient(to right, rgba(98, 205, 255, .1),rgba(98, 205, 255, 1));
      }
      .progress-width2 {
         height: 6px;
         background: linear-gradient(to right, rgba(150, 245, 248, .1),rgba(150, 245, 248, 1));
      }
      .progress-width3 {
         height: 6px;
         background: linear-gradient(to right, rgba(98, 255, 168, .1),rgba(98, 255, 168, 1));
      }
      &-circle99 {
         position: absolute;
         top: 50%;
         transform: translate(-50%, -50%);
         width: 10px;
         height: 10px;
         background: rgba(0, 128, 255, 1);
         box-shadow: 0 0 5px 3px rgba(0, 128, 255, 1);
         border-radius: 50%;
      }
      &-circle0 {
         background: rgba(0, 128, 255, 1);
         box-shadow: 0 0 5px 3px rgba(0, 128, 255, 1);
      }
      &-circle1 {
         background: rgba(98, 205, 255, 1);
         box-shadow: 0 0 5px 3px rgba(98, 205, 255, 1);
      }
      &-circle2 {
         background: rgba(150, 245, 248, 1);
         box-shadow: 0 0 5px 3px rgba(150, 245, 248, 1);
      }
      &-circle3 {
         background: rgba(98, 255, 168, 1);
         box-shadow: 0 0 5px 3px rgba(98, 255, 168, 1);
      }
   }
</style>