<template>
  <div class="service-box">
    <!-- 统计 -->
    <div class="service-statistics"
      ref="service-box">
      <div class="service-statistics-view">
        <div class="service-statistics-view-num">{{ serviceData.serviceCaseNum }}</div>
        <img src="../../../../assets/images/bigScreen/home/ajhp.png"
          :style="{ width: hostWidth +'px' }"
          alt=""
          srcset="">
        <div class="service-statistics-view-name">服务案件数</div>
      </div>
      <div class="service-statistics-view">
        <div class="service-statistics-view-num">{{ serviceData.serviceAreaNum }} <span>区县</span></div>
        <img src="../../../../assets/images/bigScreen/home/fwqy.png"
          :style="{ width: hostWidth +'px' }"
          alt=""
          srcset="">
        <div class="service-statistics-view-name">服务区域数</div>
      </div>
      <div class="service-statistics-view">
        <div class="service-statistics-view-num">{{ serviceData.serviceEvaluateNum }} <span>%</span></div>
        <img src="../../../../assets/images/bigScreen/home/fwhp.png"
          :style="{ width: hostWidth +'px' }"
          alt=""
          srcset="">
        <div class="service-statistics-view-name">服务好评数</div>
      </div>
    </div>

    <!-- 历史赔付率 -->
    <header-name title="历史赔付率" />
    <div class="service-bar"
      ref="service-bar">
      <Chart style="height:100%;min-height: 100px"
        ref="chartBox"
        :option="barOptions"></Chart>
    </div>
  </div>
</template>

<script>
import HeaderName from '@/views/BigScreenDisplay/components/HeaderName';
import Chart from "@/components/Chart/Charts";
import * as echarts from 'echarts';
import { getServiceOnlineView } from "@/api/BigScreenDisplay";
export default {
  components: {
    HeaderName,
    Chart
  },
  data() {
    return {
      barOptions: {},
      hostWidth: 0,
      serviceData: {}
    };
  },
  created() {
    //  this.init();
    this.$nextTick(() => {
      const clientWidth = this.$refs['service-box'].offsetWidth * 0.23;
      const clientHeight = this.$refs['service-box'].offsetHeight * 0.6;
      this.hostWidth = clientWidth > clientHeight ? clientHeight : clientWidth;
    });
  },
  methods: {
    init() {
      getServiceOnlineView().then(res => {
        this.serviceData = res.data;
        this.$emit('changeClaimService', Number(res.data.serviceCaseNum || 0))
        this.initCharts(res.data.historicalLossRate);
      });
    },
    getSize(res, type) {
      const clientWidth = this.$refs['service-bar'].offsetWidth;
      const clientHeight = this.$refs['service-bar'].offsetHeight;
      if (!clientWidth) return;
      const fontSize = 100 * (clientWidth / 1920);
      const fontSize2 = 100 * (clientHeight / 1080);
      return res * (type == 2 ? fontSize2 : fontSize);
    },
    initCharts(dataList) {
      const sizeHNum = this.getSize(0.3, 2);
      const barData1 = dataList.map(val => val.ratio); //昨日销售额柱状图数据
      const barData3 = dataList.map(val => 10); //今日销售额
      const data5 = [];
      var date = dataList.map(val => val.label);
      for (let i = 0; i < barData1.length; i++) {
        data5.push(Number(barData1[i] || 0) + Number(barData3[i] || 0));
      }
      this.barOptions = {
        tooltip: {
          trigger: 'axis',
          borderWidth: 0,
          padding: 5,
          textStyle: {
            color: '#fff',
            fontSize: sizeHNum * 1,
          },
          axisPointer: {
            type: 'shadow'
          },
          formatter: (params) => {
            let str = '';
            // str = `<div>  
            //      <div>
            //        <p style="font-size: 14px;color:#fff">昨日销售额：${ params[0].value }万元</p>
            //        <p style="font-size: 14px;color:#fff">今日销售额：${ params[1].value }万元</p>
            //        <p style="font-size: 14px;color:#fff">今日毛利：${ params[2].value }%</p>
            //        <p style="font-size: 14px;color:#fff">环比(昨日)：${ params[3].value }%</p>
            //        <p style="font-size: 14px;color:#fff">今日毛利率：${ params[2].value }%</p>

            //      </div>
            //      </div>`;
            return str;
          },

        },

        grid: {
          left: sizeHNum * 8,
          top: sizeHNum * 4,
          right: sizeHNum * 3,
          bottom: sizeHNum * 6,
        },
        xAxis: {
          data: date,
          axisLine: {
            show: false, //隐藏X轴轴线
          },
          axisTick: {
            show: false, //隐藏X轴刻度
            alignWithLabel: true,
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: "#FFFFFF", //X轴文字颜色
              fontSize: sizeHNum * 2,
            },
            // margin: 12,
            interval: 0,
          },
        },
        yAxis: [{
          type: "value",
          // 			max: 2000,
          // 			splitNumber: 8,
          nameTextStyle: {
            color: "#FFFFFF",
            fontSize: sizeHNum * 2,
          },
          splitLine: {
            lineStyle: {
              type: "dashed",
              color: "rgba(13, 151, 235, .3)"
            }
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: "#FFFFFF",
              fontSize: sizeHNum * 2,
            },
          },
        },
        {
          type: "value",
          name: "占比%",
          formatter: '{value} %',
          show: false,
          // 			max: 100,
          // 			splitNumber: 8,
          nameTextStyle: {
            color: "#FFFFFF",
            fontSize: sizeHNum * 2,
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "#000000",
              type: "solid",
            },
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: "#FFFFFF",
              fontSize: sizeHNum * 2,
            },
          },
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
        },
        ],
        series: [
          {
            stack: "总额",
            type: "bar",
            barWidth: sizeHNum * 2.6,
            label: {
              show: true,
              position: 'top',
          formatter: (params) => {
            let str = params.value + '%';
            return str;
          },
              fontSize: sizeHNum * 2.6,
              color: '#fff',
              offset: [0,  -(sizeHNum * 3)]
            },
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 1,
                  color: "#205DDB",
                },
                //  {
                //    offset: 0.5,
                //    color: '#01AC62',
                //  },
                {
                  offset: 0,
                  color: '#1BDFFC',
                },
                ]),
              },
            },
            data: barData1,
          },

          {
            name: '上部圆',
            type: 'pictorialBar',
            silent: true,
            symbolSize: [sizeHNum * 2.6, sizeHNum],
            symbolOffset: [0, -2],
            symbolPosition: 'end',
            z: 12,
            color: 'rgba(27, 223, 252, .8)',
            data: barData1,
            tooltip: {
              show: false
            }
          },
          {
            name: '底部圆',
            type: 'pictorialBar',
            silent: true,
            symbolSize: [sizeHNum * 2.6, sizeHNum],
            symbolOffset: [0, 1],
            z: 12,
            color: 'rgba(32, 93, 219, .6)',
            data: barData1,
            tooltip: {
              show: false
            }
          },


          {
            name: "今日销售额",
            type: "bar",
            stack: "总额",
            barWidth: sizeHNum * 2.6,
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 1,
                  color: " rgba(18, 81, 210, 0.09)",

                },
                {
                  offset: 0,
                  color: "rgba(27, 223, 252, 0.09)",
                },
                ]),
              },
            },
            data: barData3,

          },
          {
            name: '',
            type: 'pictorialBar',
            silent: true,
            symbolSize: [sizeHNum * 2.6, sizeHNum],
            symbolOffset: [0, '-50%'],
            symbolPosition: 'end',
            z: 11,
            color: 'rgba(18, 81, 210, 0.19)',
            data: data5,
            tooltip: {
              show: false
            }

          },

        ],
      };
    }
  }
}
</script>

<style lang="scss" scoped>
.service-box {
  display: flex;
  flex-direction: column;
  height: 91.8%;
  padding: 0 3%;
  .service-bar {
    flex: 1;
    margin-bottom: 1%;
  }
  .service-statistics {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1%;
    &-view {
      text-align: center;
      &-name {
        width: 100%;
        font-size: 0.15rem;
        color: #aaddff;
      }
      &-num {
        font-family: 'ZhanKuQingKeHuangYouTi';
        width: 100%;
        font-size: 0.33rem;
        color: #43bafe;
        span {
          font-size: 0.12rem;
          color: #ffffff;
        }
      }
      img {
        width: 0.8rem;
        display: block;
        margin: 1.5% auto 3.5%;
      }
    }
  }
}

@media only screen and (max-height: 700px) {
  .service-statistics {
    margin-top: 0% !important;
  }
}
</style>