<template>
  <div class="equipment-box">
    <!-- 设备总数 -->
    <header-name title="设备总数">
      <template>
        <div class="header-slot">
          <span class="total">
            <countTo :startVal='0' ref="allTotal" :autoplay="false" :endVal='Number(statisticsData.allTotal || 0)' separator="" :duration='2500'></countTo>
            <!-- {{ statisticsData.allTotal }} -->
          </span>
          <span class="header-text">台（ 月新增 ）<span class="contrast">{{ statisticsData.deviceMonthNew }} <img src="../../../../assets/images/bigScreen/home/up.png"
                v-if="statisticsData.deviceMonthNew >= 0"
                alt=""
                srcset=""><img v-else
                src="../../../../assets/images/bigScreen/home/decline.png"
                alt=""
                srcset=""></span></span>
        </div>
      </template>
    </header-name>
    <div class="statistics">
      <div class="statistics-view"
        v-for="item,index in statisticsList"
        :key="index">
        <div class="statistics-view-title">{{ item.label }}</div>
        <div class="statistics-view-box"
          :style="{ justifyContent: item.contrastValue ? 'space-between' : '' }">
          <div class="statistics-view-box-num">
            <!-- {{ statisticsData[item.value] }} -->
            <countTo :startVal='0' :ref="item.value" :autoplay="false" :endVal='Number(statisticsData[item.value] || 0)' separator="" :decimals="item.value == 'onlineRate' ? 2 : 0" :duration='2500'></countTo>
            </div>
          <div class="statistics-view-box-text">
            {{ item.remarks }}
            <span v-if="item.isContrast && (statisticsData[item.contrastValue] && statisticsData[item.contrastValue] > 0)"
              class="contrast">
               {{ statisticsData[item.contrastValue] }} 
               <img src="../../../../assets/images/bigScreen/home/up.png"
                alt=""
                srcset=""> <!-- decline --></span>
            <span v-else-if="item.isContrast"
              class="contrast">
               {{ statisticsData[item.contrastValue] }} 
               <img src="../../../../assets/images/bigScreen/home/decline.png"
                alt=""
                srcset=""> <!-- decline --></span>
          </div>
        </div>
      </div>
    </div>

    <!-- 机械类型 -->
    <header-name title="机械类型" />
    <div class="mechanical">
      <div class="mechanical-charts"
        ref="pie-box">
        <Chart style="height:100%;min-height: 100px"
          ref="chartBox"
          :option="pieOptions"
          @onReady="handlePieReady"></Chart>
        <div class="mechanical-charts-num">
          <div class="mechanical-charts-num-head">{{ mechanicalList[pieIndex] ? mechanicalList[pieIndex].label : '' }}</div>
          <div>{{ mechanicalList[pieIndex] ? mechanicalList[pieIndex].value : '' }}<span class="mechanical-charts-unit">台</span></div>
          <div class="mechanical-charts-num-proportion">{{ mechanicalList[pieIndex] ? mechanicalList[pieIndex].ratio : '' }}</div>
        </div>
      </div>
      <div class="mechanical-progress">
        <div class="mechanical-progress-box"
          v-for="item,index in mechanicalList"
          :key="index">
          <div class="mechanical-progress-box-text">
            <div class="mechanical-progress-box-left">
              <div :class="['mechanical-progress-box-rank', index == mechanicalList.length -1 ? 'mechanical-progress-box-rank2' : '']">{{ index + 1 }}</div>
              <span class="mechanical-progress-box-name">{{ item.label }}</span>
            </div>
            <div class="mechanical-progress-box-right">
              <span class="mechanical-progress-box-proportion">{{ item.progressValue }}%</span>
              <span class="mechanical-progress-box-unit">{{ item.value }} <span>台</span></span>
            </div>
          </div>
          <div class="mechanical-progress-box-drawing">
            <equipment-progress :width="item.progressValue"
              :index="index" />
          </div>
        </div>
      </div>
    </div>
    <!-- 保障情况 -->
    <header-name title="保障情况" />
    <div class="guarantee-box">
      <div :style="{width: insuredWidth + '%', backgroundColor: '#1E83FF'}"></div>
      <div :style="{width: notInsuredWidth + '%', backgroundColor: '#FFFD37'}"></div>
      <div :style="{width: entrustInsuredWidth + '%', backgroundColor: '#97EBFF'}"></div>
    </div>
    <div class="guarantee-legend">
      <div class="guarantee-legend1">
        <div class="guarantee-legend1-circle"></div>
        <div class="guarantee-legend1-text">投保：{{ statisticsData.insured || 0 }}台</div>
      </div>
      <div class="guarantee-legend3">
        <div class="guarantee-legend3-circle"></div>
        <div class="guarantee-legend3-text">脱保：{{ statisticsData.notInsured || 0 }}台</div>
      </div>
      <div class="guarantee-legend2">
        <div class="guarantee-legend2-circle"></div>
        <div class="guarantee-legend2-text">其他：{{ statisticsData.entrustInsured || 0 }}台</div>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderName from '@/views/BigScreenDisplay/components/HeaderName';
import equipmentProgress from '@/views/BigScreenDisplay/components/home/equipmentProgress';
import Chart from "@/components/Chart/Charts";
import { GetPercent } from "@/utils/index";
import { getDeviceOnlineView } from "@/api/BigScreenDisplay";
import countTo from 'vue-count-to';
export default {
  components: { HeaderName, Chart, equipmentProgress, countTo },
  data() {
    return {
      statisticsList: [
        {
          label: '在线数量',
          value: 'onlineTotal',
          remarks: '较昨日',
          isContrast: true,
          contrastValue: 'onlineNew'
        },
        {
          label: '在线率',
          value: 'onlineRate',
          remarks: '%',
        },
        {
          label: '累计监控工作时长',
          value: 'allTotalTime',
          remarks: '小时',
        },
        {
          label: '累计监控油耗',
          value: 'allTotalOil',
          remarks: '升',
        }
      ],
      statisticsData: {},
      mechanicalList: [],
      pieOptions: {},
      pieIndex: 0,
      pieTime: null,
      pieChart: null,
      insuredWidth: 0,
      entrustInsuredWidth: 0,
      notInsuredWidth: 0
    };
  },
  created() {
    //  this.init();
  },
  methods: {
    init() {
      getDeviceOnlineView({
         accountName: this.$store.state.userInfo.name
      }
).then(res => {
        this.statisticsData = res.obj;
        this.$emit('onVest', res.obj);
        this.mechanicalList = res.obj.areaVehicleCount.map(val => {
          return {
            label: val.label,
            name: val.label,
            value: val.value,
            progressValue: val.ratio
          };
        });
        this.statisticsList.map(val=>{
            this.$refs[val.value] && this.$refs[val.value][0] ? this.$refs[val.value][0].start() : ''
        })
        this.$refs['allTotal'].start()
        this.$nextTick(() => {
          this.conductOption(this.mechanicalList);
        });
        this.insuredWidth = GetPercent(Number(res.obj.insured), Number(res.obj.insured || 0) + Number(res.obj.entrustInsured || 0) + Number(res.obj.notInsured || 0));
        this.entrustInsuredWidth = GetPercent(Number(res.obj.entrustInsured),Number(res.obj.insured || 0) + Number(res.obj.entrustInsured || 0) + Number(res.obj.notInsured || 0));
        this.notInsuredWidth = GetPercent(Number(res.obj.notInsured), Number(res.obj.insured || 0) + Number(res.obj.entrustInsured || 0) + Number(res.obj.notInsured || 0));
      });
    },
    getSize(res, type) {
      const clientWidth = this.$refs['pie-box'].offsetWidth;
      const clientHeight = this.$refs['pie-box'].offsetHeight;
      if (!clientWidth) return;
      const fontSize = 100 * (clientWidth / 1920);
      const fontSize2 = 100 * (clientHeight / 1080);
      return res * (type == 2 ? fontSize2 : fontSize);
    },
    conductOption(arrData) {
      const sizeHNum = this.getSize(0.3, 2);
      this.pieOptions = {
        "animation": true,
        "legend": {
          "left": "center",
          "textStyle": {
            "color": "#ffffff",
            "fontSize": sizeHNum
          },
          itemWidth: sizeHNum * 1.8,
          itemHeight: sizeHNum * 1.8,
          "right": "0",
          "bottom": "2%",
          textStyle: {

            width: sizeHNum * 15,
            fontWeight: 600,
            "fontSize": sizeHNum * 1.5,
            color: "#fff"
          },
          "data": arrData
        },
        "series": [{
          "type": "pie",
          "center": ["50%", "40%"],
          "radius": [sizeHNum * 9 + "%", sizeHNum * 12 + "%"],
          itemStyle: {
          },
          "color": ["#0080FF", "#62CDFF", "#96F5F8", "#62FFA8", "#00FFA8", "#9F17FF", "#FFE400", "#FE2C8A"],
          "startAngle": 135,
          "labelLine": {
            "normal": {
              "length": 25
            }
          },
          "label": {
            "normal": {
              show: false
            }
          },
          "data": arrData
        }]
      };
    },
    handlePieReady(myChart) {
      if (this.pieTime) {
        clearInterval(this.pieTime); //销毁定时器
        this.pieTime = null;
      }
      let index = 0;//默认选中第一个
      const that = this;
      this.pieIndex = 0;
      this.pieChart = myChart;
      that.setPieRotate();
      myChart.on('mouseover', function (e) {
        if (e.dataIndex != index) {
          myChart.dispatchAction({
            type: 'downplay',
            seriesIndex: 0,
            dataIndex: that.pieIndex,
          });
          that.pieIndex = e.dataIndex;
        }
        clearInterval(that.pieTime);
      });
      myChart.on('mouseout', function (e) {
        index = e.dataIndex;
        that.pieIndex = e.dataIndex;
        that.pieTime ? clearInterval(that.pieTime) : '';
        that.setPieRotate();
      });
    },
    setPieRotate() {
      this.pieChart.dispatchAction({
        type: 'highlight',
        seriesIndex: 0,
        dataIndex: this.pieIndex,//默认选中第一个
      });
      this.pieTime = setInterval(() => {
        this.pieChart.dispatchAction({
          type: 'downplay',
          seriesIndex: 0,
          dataIndex: this.pieIndex,
        });
        this.pieIndex >= this.mechanicalList.length - 1 ? this.pieIndex = 0 : this.pieIndex += 1;
        this.pieChart.dispatchAction({
          type: 'highlight',
          seriesIndex: 0,
          dataIndex: this.pieIndex,//默认选中第一个
        });
      }, 5000);
    }
  },

  destroyed() {
    clearInterval(this.pieTime); //销毁定时器
    this.pieTime = null;
  },
  deactivated() {
    clearInterval(this.pieTime); //销毁定时器
    this.pieTime = null;
  },

}
</script>

<style lang="scss" scoped>
.equipment-box {
  height: 100%;
  padding: 0.2rem;
  .header-slot {
    display: flex;
    align-items: flex-end;
    .total {
      font-family: 'ZhanKuQingKeHuangYouTi';
      font-size: 0.29rem;
      line-height: 0.26rem;
      margin-left: 0.26rem;
      margin-right: 0.07rem;
      color: #fffd37;
    }
    .header-text {
      font-size: 0.11rem;
      font-weight: 300;
      color: #ffffff;
    }
  }
  .statistics {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 3%;
    height: 25%;
    margin-bottom: 0.1rem;
    > div {
      width: 42%;
      height: 40%;
      background-size: 100% 100%;
      background-image: url('../../../../assets/images/bigScreen/home/sbNum.png');
    }
    &-view {
      display: flex;
      align-content: space-between;
      flex-wrap: wrap;
      padding: 0.06rem 0.14rem;
      &-title {
        width: 100%;
        font-size: 0.15rem;
        font-weight: 300;
        color: #ffffff;
      }
      &-box {
        width: 100%;
        display: flex;
        //   justify-content: space-between;
        align-content: space-between;
        &-num {
          display: flex;
          align-items: flex-end;
          font-family: 'ZhanKuQingKeHuangYouTi';
          font-size: 0.26rem;
          line-height: 0.76;
          color: #fffd37;
          margin-right: 0.08rem;
        }
        &-text {
          display: flex;
          align-items: flex-end;
          font-size: 0.13rem;
          font-weight: 300;
          color: #ffffff;
        }
      }
    }
  }
  .contrast {
    color: #0def02;
    img {
      width: 0.11rem;
    }
  }
  .mechanical {
    display: flex;
    height: 42%;
    margin-bottom: 2%;
    > div {
      flex: 1;
    }
    &-charts {
      position: relative;
      &-num {
        position: absolute;
        left: 50%;
        top: 40%;
        transform: translate(-50%, -50%);
        font-size: 0.2rem;
        font-weight: bold;
        color: #ffffff;
        text-align: center;
        &-head {
          font-size: 0.16rem;
          color: #0267cb;
          margin-bottom: 0.06rem;
        }
        &-proportion {
          font-size: 0.2rem;
          color: #fff;
          font-weight: 400;
          margin-top: 0.06rem;
        }
      }
      &-unit {
        font-size: 0.12rem;
        font-weight: 400;
        color: #ffffff;
        margin-left: 2px;
      }
    }
    &-progress {
      &-box {
        height: 25%;
        &-text {
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 0.2rem;
          font-size: 0.12rem;
          color: #ffffff;
          margin-bottom: 0.16rem;
        }
        &-unit {
          font-size: 0.16rem;
          font-weight: bold;
          color: #ffffff;
          span {
            font-size: 0.12rem;
          }
        }
        &-left {
          display: flex;
          align-items: center;
          height: 0.2rem;
        }
        &-rank {
          width: 0.18rem;
          height: 0.18rem;
          font-size: 0.12rpx;
          font-weight: bold;
          color: #ffffff;
          text-align: center;
          line-height: 0.21rem;
          margin-right: 0.08rem;
          background-image: url('../../../../assets/images/bigScreen/home/mechanicalRank1.png');
          background-size: 100% 100%;
        }
        &-rank2 {
          background-image: url('../../../../assets/images/bigScreen/home/mechanicalRank2.png');
        }
        &-proportion {
          color: #ffffff;
          margin-right: 4px;
        }
        &-drawing {
        }
      }
    }
  }
  .guarantee-box {
    display: flex;
    height: 3%;
    border-radius: 4px;
    overflow: hidden;
    margin-top: 2%;
    margin-bottom: 2%;
    background-color: #eee;
  }
  .guarantee-legend {
    display: flex;
    justify-content: space-around;
    .guarantee-legend1 {
      display: flex;
      align-items: center;
      &-circle {
        width: 0.14rem;
        height: 0.14rem;
        margin-right: 8px;
        border-radius: 50%;
        background-color: rgba(30, 131, 255, 1);
      }
      &-text {
        font-size: 0.16rem;
        color: #ffffff;
      }
    }
    .guarantee-legend2 {
      display: flex;
      align-items: center;
      &-circle {
        width: 0.14rem;
        height: 0.14rem;
        margin-right: 8px;
        border-radius: 50%;
        background-color: rgba(151, 235, 255, 1);
      }
      &-text {
        font-size: 0.16rem;
        color: #ffffff;
      }
    }
    .guarantee-legend3 {
      display: flex;
      align-items: center;
      &-circle {
        width: 0.14rem;
        height: 0.14rem;
        margin-right: 8px;
        border-radius: 50%;
        background-color: rgba(255, 253, 55, 1);
      }
      &-text {
        font-size: 0.16rem;
        color: #ffffff;
      }
    }
  }
}

@media only screen and (max-height: 800px) {
  .equipment-box {
    padding: 0.08rem !important;
    .statistics {
      margin-top: 2%;
      margin-bottom: 1%;
    }
    .guarantee-box {
      margin-top: 1%;
      margin-bottom: 1%;
    }
    .mechanical {
      margin-bottom: 1%;
    }
  }
}
</style>