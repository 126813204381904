<template>
  <div class="risk-box">
    <div class="risk-box-tab">
      <div
        class="risk-box-tab-view"
        :class="{ 'risk-box-tab-view-bg': type == 1 }"
        @click="handleChangeType(1)"
      >
        累计
      </div>
      <div
        class="risk-box-tab-view"
        :class="{ 'risk-box-tab-view-bg': type == 2 }"
        @click="handleChangeType(2)"
      >
        今年
      </div>
    </div>
    <div class="risk-box-content" ref="risk-box">
      <div class="risk-box-content-box">
        <div class="risk-box-content-box-view">
          <img
            src="../../../../assets/images/bigScreen/home/gjNum.png"
            :style="{ width: hostWidth + 'px' }"
            alt=""
            srcset=""
          />
          <div>
            <div class="risk-box-content-box-view-num">
              {{
                type == 1
                  ? riskData.totalAlarms
                  : riskData.currentYearTotalAlarms
              }}
            </div>
            <div class="risk-box-content-box-view-text">告警次数</div>
          </div>
        </div>
        <div class="risk-box-content-box-view">
          <img
            src="../../../../assets/images/bigScreen/home/zdgy.png"
            :style="{ width: hostWidth + 'px' }"
            alt=""
            srcset=""
          />
          <div>
            <div class="risk-box-content-box-view-num">
              {{
                type == 1
                  ? riskData.activeIntervention
                  : riskData.currentYearActiveIntervention
              }}
            </div>
            <div class="risk-box-content-box-view-text">主动干预</div>
          </div>
        </div>
        <div class="risk-box-content-box-view">
          <img
            src="../../../../assets/images/bigScreen/home/fkpx.png"
            :style="{ width: hostWidth + 'px' }"
            alt=""
            srcset=""
          />
          <div>
            <div class="risk-box-content-box-view-num">
              {{
                type == 1
                  ? riskData.totalRiskControlTrain
                  : riskData.currentYearTotalRiskControlTrain
              }}
            </div>
            <div class="risk-box-content-box-view-text">累计风控培训</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getRiskControlOnlineView } from "@/api/BigScreenDisplay";
export default {
  data() {
    return {
      type: 1,
      hostWidth: 0,
      riskData: {},
      timeObj: null,
    };
  },
  created() {
    // this.init()
    this.$nextTick(() => {
      const clientWidth = this.$refs["risk-box"].offsetWidth * 0.23;
      const clientHeight = this.$refs["risk-box"].offsetHeight * 0.6;
      this.hostWidth = clientWidth > clientHeight ? clientHeight : clientWidth;
    });
  },
  methods: {
    handleChangeType(type, flag) {
      if (flag) {
        this.timeObj ? clearInterval(this.timeObj) : ""; //销毁定时器
        this.timeObj = setInterval(() => {
          this.handleChangeType(this.type == 1 ? 2 : 1);
        }, 10000);
      }
      this.type = type;
    },
    destroyed() {
      clearInterval(this.timeObj); //销毁定时器
    },
    deactivated() {
      clearInterval(this.timeObj); //销毁定时器
    },
    init() {
      getRiskControlOnlineView({
        accountName: this.$store.state.userInfo.name,
      }).then((res) => {
        this.riskData = res.obj;
        this.timeObj ? clearInterval(this.timeObj) : ""; //销毁定时器
        this.timeObj = setInterval(() => {
          this.handleChangeType(this.type == 1 ? 2 : 1, true);
        }, 10000);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.risk-box {
  display: flex;
  height: 84.2%;
  padding: 1.2%;
  padding-top: 0;
  &-tab {
    width: 0.4rem;
    &-view {
      display: flex;
      align-items: center;
      height: 50%;
      line-height: 200%;
      text-align: center;
      font-size: 0.14rem;
      padding: 0 0.1rem;
      color: #ffffff;
      cursor: pointer;
      background: rgba(2, 103, 203, 0.1);
      &-bg {
        font-weight: bold;
        color: #96f5f8;
        background: #0267cb;
      }
    }
  }
  &-content {
    flex: 1;
    position: relative;
    &-box {
      position: absolute;
      left: 2%;
      right: 4%;
      bottom: 6%;
      display: flex;
      justify-content: space-around;
      background-image: url("../../../../assets/images/bigScreen/home/fkPedestal.png");
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: bottom;
      &-view {
        position: relative;
        // width: 23%;
        transform: translateY(-26%);
        > div {
          position: absolute;
          width: 100%;
          top: -0.08rem;
          text-align: center;
        }
        &-num {
          font-family: "ZhanKuQingKeHuangYouTi";
          font-size: 0.24rem;
          color: #12bbff;
          margin-bottom: 0.08rem;
        }
        &-text {
          font-size: 0.12rem;
          color: #12bbff;
        }
      }
    }
  }
}
@media only screen and (max-height: 700px) {
  .risk-box-content-box-view {
    transform: translateY(-13%) !important;
  }
}
</style>
