<template>
  <div class="policy-box"
    ref="policy-box">
    <div class="policy-host">
      <img :style="{width: hostWidth +'px'}"
        src="@/assets/images/bigScreen/home/zbe.png" />
      <div class="policy-content">
        <div class="policy-content-num">
          <span class="policy-content-num-value">
            <countTo :startVal='0' ref="totalPremium" :autoplay="false" :endVal='Number(policyData.totalPremium || 0)' separator="" :decimals="2" :duration='2500'></countTo>
            <!-- {{ policyData.totalPremium || 0 }} -->
            </span>
          <span class="policy-content-num-text">亿元</span>
        </div>
        <div class="policy-content-text">总保额</div>
      </div>
    </div>
    <div class="policy-view policy-view-left"><img style="width:100%"
        src="@/assets/images/bigScreen/home/bdBox.png" />
      <div class="policy-content">
        <div class="policy-content-num">
          <span class="policy-content-num-value">
            <countTo :startVal='0' ref="servicePolicyNum" :autoplay="false" :endVal='Number(policyData.servicePolicyNum || 0)' separator="" :duration='2500'></countTo>
            <!-- {{ policyData.servicePolicyNum || 0 }} -->
            </span>
          <span class="policy-content-num-text">张</span>
        </div>
        <div class="policy-content-text">服务保单</div>
      </div>
    </div>
    <div class="policy-view policy-view-underneath"><img style="width:100%"
        src="@/assets/images/bigScreen/home/bdBox.png" />
      <div class="policy-content">
        <div class="policy-content-num">
          <span class="policy-content-num-value">
            <countTo :startVal='0' ref="serviceDeviceNum" :autoplay="false" :endVal='Number(policyData.serviceDeviceNum || 0)' separator="" :duration='2500'></countTo>
            <!-- {{ policyData.serviceDeviceNum || 0 }} -->
            </span>
          <span class="policy-content-num-text">台</span>
        </div>
        <div class="policy-content-text">保障设备</div>
      </div>
    </div>
    <div class="policy-view policy-view-right" style="cursor: pointer;" @click="handleNavi('/BigScreenDisplayCooperation')"><img style="width:100%"
        src="@/assets/images/bigScreen/home/bdBox.png" />
      <div class="policy-content">
        <div class="policy-content-num">
          <span class="policy-content-num-value">
            <countTo :startVal='0' ref="companyNum" :autoplay="false" :endVal='Number(policyData.companyNum || 0)' separator="" :duration='2500'></countTo>
            <!-- {{ policyData.companyNum || 0 }} -->
            </span>
          <span class="policy-content-num-text"></span>
        </div>
        <div class="policy-content-text">服务保司</div>
      </div>
    </div>
  </div>
</template>

<script>
import { getPolicyOnlineView } from "@/api/BigScreenDisplay";
import countTo from 'vue-count-to';
export default {
   components: { countTo },
  created() {
   // this.init()
    this.$nextTick(() => {
      const clientWidth = this.$refs['policy-box'].offsetWidth;
      const clientHeight = this.$refs['policy-box'].offsetHeight;
      this.hostWidth = clientWidth > clientHeight ? clientHeight * 0.6 : clientWidth * 0.6;
    });
  },
  data() {
    return {
      hostWidth: 0,
      policyData: {}
    };
  },
  methods: {
    init() {
      getPolicyOnlineView().then(res=>{
        res.data.totalPremium ? res.data.totalPremium = (Number(res.data.totalPremium) / 10000).toFixed(2) || 0 : ''
         this.policyData = res.data;
         this.$refs['companyNum'].start();
         this.$refs['serviceDeviceNum'].start();
         this.$refs['servicePolicyNum'].start();
         this.$refs['totalPremium'].start();
        //  res.data.serviceDeviceNum ? Number(res.data.serviceDeviceNum) + 
         this.$emit('changePolicyService', res.data.servicePolicyNum ? Number(res.data.servicePolicyNum) : 0 || 0)
      })
    },
    handleNavi(path){
      this.$router.push({
        path
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.policy-box {
  position: relative;
  width: 100%;
  height: 89%;
  .policy-host {
    position: absolute;
    // width: 60%;
    // max-height: 60%;
    top: 2%;
    left: 50%;
    transform: translateX(-50%);

    .policy-content {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      display: flex;
      flex-wrap: wrap;
      align-content: center;
      &-num {
        width: 100%;
        text-align: center;
        margin-top: 6%;
        &-text {
          font-size: 0.12rem;
          color: #fff;
        }
        &-value {
          font-family: 'ZhanKuQingKeHuangYouTi';
          font-size: 0.38rem;
          font-weight: bold;
          background-image: -webkit-linear-gradient(
            bottom,
            rgba(250, 255, 103, 1),
            rgba(255, 255, 255, 1)
          );
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
      &-text {
        width: 100%;
        text-align: center;
        font-size: 0.2rem;
        color: #fff;
        margin-top: 6%;
      }
    }
  }
  .policy-view {
    position: absolute;
    width: 26%;

    .policy-content {
      position: absolute;
      left: 0;
      right: 0;
      top: 10%;
      bottom: 10%;
      display: flex;
      flex-wrap: wrap;
      align-content: space-around;
      &-num {
        width: 100%;
        text-align: center;
        &-text {
          font-size: 0.12rem;
          color: #fff;
        }
        &-value {
          font-family: 'ZhanKuQingKeHuangYouTi';
          font-size: 0.28rem;
          font-weight: bold;
          background-image: -webkit-linear-gradient(
            bottom,
            rgba(250, 255, 103, 1),
            rgba(255, 255, 255, 1)
          );
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
      &-text {
        width: 100%;
        text-align: center;
        font-size: 0.14rem;
        color: #fff;
      }
    }
  }
  .policy-view-left {
    left: 4%;
    top: 50%;
    transform: translateY(-50%);
  }
  .policy-view-right {
    right: 4%;
    top: 50%;
    transform: translateY(-50%);
  }
  .policy-view-underneath {
    bottom: 8%;
    left: 50%;
    transform: translateX(-50%);
  }
}

@media only screen and (max-height: 700px) {
   .policy-view-underneath {
    bottom: 2% !important;
  }
}
</style>